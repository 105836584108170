
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {CoachPlayersProfileHealthRecordsListIndex} from "@/models/app/coach/players/profile/health-records/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachPlayersProfileHealthRecordsListFormCreateIndexVue from "@/views/app/coach/players/profile/health-records/list/form/create.vue";
import CoachPlayersProfileHealthRecordsListFormUpdateIndexVue from "@/views/app/coach/players/profile/health-records/list/form/update.vue";
import CoachPlayersProfileHealthRecordsListFormRemoveIndexVue from "@/views/app/coach/players/profile/health-records/list/form/remove.vue";
import CoachPlayersProfileHealthRecordsListFormLookupDocumentIndexVue from "@/views/app/coach/players/profile/health-records/list/form/lookup-document.vue";
import CoachPlayersProfileHealthRecordsListFormUploadDocumentIndexVue from "@/views/app/coach/players/profile/health-records/list/form/upload-document.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachPlayersProfileHealthRecordsListFormCreateIndexVue,
    CoachPlayersProfileHealthRecordsListFormUpdateIndexVue,
    CoachPlayersProfileHealthRecordsListFormRemoveIndexVue,
    CoachPlayersProfileHealthRecordsListFormLookupDocumentIndexVue,
    CoachPlayersProfileHealthRecordsListFormUploadDocumentIndexVue,
  },
})
export default class CoachPlayersProfileHealthRecordsListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  healthRecords: Array<CoachPlayersProfileHealthRecordsListIndex> = [];

  async getHealthRecords(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/health-records`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.healthRecords = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.user_id = this.$route.params.id;
    await this.getHealthRecords();
  }
}
