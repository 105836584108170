// Store
import store from "@/store/index";

export interface CoachPlayersProfileHealthRecordsListFormUploadDocument {
  id: string;
  health_record_id: string;
  organization_id: string;
  content: {
    title: string;
    document: string;
  };
}

export class CoachPlayersProfileHealthRecordsListFormUploadDocument implements CoachPlayersProfileHealthRecordsListFormUploadDocument {
  id: string;
  health_record_id: string;
  organization_id: string;
  content: {
    title: string;
    document: string;
  };

  constructor(health_record_id?: string) {
    this.id = "";
    this.health_record_id = health_record_id ?? "";
    this.organization_id =  store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      title: "",
      document: "",
    };
  }
}