
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersProfileNavigationVue from "@/components/coach/players/profile/navigation.vue";
import CoachPlayersProfileHealthRecordsListIndexVue from "@/views/app/coach/players/profile/health-records/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersProfileNavigationVue,
    CoachPlayersProfileHealthRecordsListIndexVue
  },
})
export default class CoachPlayersProfileHealthRecordsIndexVue extends Vue {}
